import functions from '../functions';
import Slider from "../components/Slider";
import { list } from './list';
import { listMap } from "./list-map";

$(() => {
    listAccommodation.init();
})

const listAccommodation = {
    $form: null,
    form: null,
    defaults: {
        personsInObject: 1,
        _page: 1,
        perPage: 12,
        language: functions.getLang(),
        pool: '',
        parking: '',
        pets: '',
        internet: '',
        dateFrom: '',
        dateTo: '',
        dates: null,
        destination: '',
        countryId: '',
        objectGroupId: 1,
        objectTypeId: '',
        linkedTypeId: '',
        guests: '',
        linkedId: '',
        ski: 0,
    },
    params: {},
    filterParams: {
        tagCategoryId: null,
        objectTypeId: null,
        categoryObject: null, 
        sortBy: null, 
        objectName: null, 
        distanceBeach: null, 
        distanceCenter: null, 
        pool: null, 
        parking: null, 
        pets: null, 
        internet: null
    },
    init() {

        this.$listItems = $('.list-items');
        this.isHome = ! this.$listItems.length;

        this.$form = functions.isMobile() && ! this.isHome ? $('.search-form.search-accommodation.search-mobile') : $('.search-form.search-accommodation');
        this.form = this.$form[0];

        this.params.objectGroupId = this.$form.last().attr('data-objectgroupid') ? this.$form.last().attr('data-objectgroupid') : null;

        if(! this.form || this.params.objectGroupId != this.defaults.objectGroupId) {
            return;
        }

        this.isHome = this.$form.last().attr('data-ishome') || this.isHome;

        this.$filter = $('.search-filter');
        this.filter = this.$filter[0]; 
        
        if(! this.isHome) {
            this.listURL = this.$form.last().attr('data-listurl');
            this.defaults.objectTypeId = this.$form.last().attr('data-objecttypeid');
            this.defaults.countryId = this.defaults.destination = this.$form.last().attr('data-countryid');
            this.defaults.destination = this.$form.last().attr('data-destination') ? this.$form.last().attr('data-destination') : this.defaults.countryId;
            this.defaults.linkedTypeId = this.$form.last().attr('data-linkedtypeid');
            this.defaults.ski = this.$form.last().attr('data-ski');
            this.defaults.perPage = this.$form.last().attr('data-perpage') ? this.$form.last().attr('data-perpage') : this.defaults.perPage;

            this.getParams();
            
            this.params.tagCategoryId = this.params.tagCategoryId ? this.params.tagCategoryId : this.$form.last().attr('data-tagcategoryid');

            functions.setFormData(this.form, this.params);
            if(this.filter) {
                functions.setFormData(this.filter, this.params);
            }
            this.splitCalendarDates($('[name=dates]').val());
            this.params.guests = $('[name=guests]').val();
            this.find(this.params);
        } else {
            //this.defaults.countryId = this.defaults.destination = this.$form.last().attr('data-countryid');
            functions.setFormData(this.form, this.defaults);
        }

        $('#listMapModal').on('shown.bs.modal', e => {
            if ( e.target.id == 'listMapModal' ) {
                //this.initComponents();
                loadGoogleMaps().done(() => {
                    if( $('.list-map') ) {
                        if ( !listMap.$map ) {
                            listMap.init(this.params, this.defaults);
                        }                   
                        listMap.mapFind(this.params);
                    }
                });
            }   
        });

        if(functions.isMobile() && ! this.isHome) {
            $('.btn-search').on('click', e => {
                this.params = $.extend({}, this.defaults, functions.getFormData(this.$form));
                this.params = this.filter ? $.extend(this.params, this.filterParams, functions.getFormData(this.$filter)) : this.params;

                functions.setUrlData(this.params, true, true);
            });
        } else {
            this.$form.on('submit', e => {
                const $target = $(e.target);
                e.preventDefault();
    
                this.isHome = $target.attr('data-ishome') || this.isHome;
    
                if(this.isHome) {
                    this.params.objectGroupId = $target.attr('data-objectgroupid');
                    this.params.objectTypeId = $target.attr('data-objecttypeid');
                    this.params.countryId = this.params.destination = $target.attr('data-countryid');
                    this.params.linkedTypeId = $target.attr('data-linkedtypeid');
                    this.params.ski = this.params.linkedTypeId == 32;
                    this.params.perPage = $target.attr('data-perpage') || this.defaults.perPage;
                }

                this.params = $.extend({}, this.params, functions.getFormData($target));
                this.params = this.filter ? $.extend(this.params, this.filterParams, functions.getFormData(this.$filter)) : this.params;
                this.params._page = this.defaults._page;
                
                if(this.isHome) {
                    this.params.objectGroupId = $target.attr('data-objectgroupid');
                    this.params.objectTypeId = $target.attr('data-objecttypeid');
                    this.params.tagCategoryId = $target.attr('data-tagcategoryid');
                    //this.params.countryId = this.params.destination = $target.attr('data-countryid');
                    //this.params.linkedTypeId = $target.attr('data-linkedtypeid');
                    //this.params.ski = this.params.linkedTypeId == 32;
                    
                    
                    location = $target.attr('data-listurl') + '#' + $.param(this.params);
                } else {
                    functions.setUrlData(this.params, true, true);
                }
    
            });
    
            this.$filter.on('change', e => {
                const $target = $(e.currentTarget);
                e.preventDefault();
    
                if ( this.stopChangeEvents ) {
                    return;
                }
                
                this.params = $.extend(this.params, this.filterParams, functions.getFormData($target));
                this.params._page = this.defaults._page;
                functions.setUrlData(this.params, true);
            });
    
            this.$filter.on('submit', e => {
                e.preventDefault();
            });
        }

        $('.btn-clear-filters').on('click', e => {
            e.preventDefault();
            this.params = $.extend(this.params, this.filterParams);
            this.params._page = this.defaults._page;
            functions.setUrlData(this.params, true);
        });

        $(window).on('hashchange', e => {
            this.getParams();
            this.stopChangeEvents = true;

            if ( this.filter ) {               
                functions.setFormData(this.filter, this.params);
            }

            functions.scrollToElement(this.$listItems, 100);
            this.find(this.params);
            this.stopChangeEvents = false;
        });
    },
    getParams() {
        list.defaults = this.defaults;
        this.params = list.params = list.getParams(this.params, this.defaults, this.filterParams);
    },
    splitCalendarDates(dates) {
        list.splitCalendarDates(dates);
    },
    find(params) {
        //let destination = params.destination;
        if(params.ski) {
            params.destination = null;
        }
        list.find(params).then((response) => {
            list.findDone(response, params, this.defaults);
            // LOZAD
            window.observer.observe();
            this.initComponents();
        });
    },
    initComponents() {
        let listSlider = [];
        $('[id^=list-slider]').each((i, elem) => {
            //const length = $(elem).find('.keen-slider__slide').length;
            listSlider[i] = new Slider('#' + $(elem).attr('id'), {
                rubberband: false,
                loop: true,
                arrows: true,
                pager: false
            });
        });
    }
}